import { Component, HostListener, OnInit } from '@angular/core';
import { LocalDataStorageService } from './core/service/local-data-storage.service';
import { LoginService } from './authentication/login/service/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemConfigService } from './system-config/service/system-config.service';
import { SystemConfigModel } from './system-config/model/system-config-model';
import { ResponseCode } from './core/constant/response-code';
import { ToastrService } from 'ngx-toastr';
import { ModuleParamGlobConfigService } from './core/service/moduleFieldConfig/module-param-glob-config.service';
import { ModuleParamConfigServiceService } from './modules/module-param-config/services/module-param-config-service.service';
import { Title } from '@angular/platform-browser';
import { LogoService } from './core/service/logo-service/logo.service';
import { PartnerConst } from './shared/partner-constant/partner-const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'app';
  public systemData: Array<SystemConfigModel>;
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'q') {
        this.router.navigate(['/module/quotation/addUpdate'])
        event.preventDefault();
    }
    if (event.shiftKey && event.key === 'Q') {
      this.router.navigate(['/module/quotation'])
      event.preventDefault();
  }
  }
  constructor(
    private localDataStorageService: LocalDataStorageService,
    private loginService: LoginService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private moduleParamGlobConfigService: ModuleParamGlobConfigService,
    private moduleParamConfigService: ModuleParamConfigServiceService,
    private sysConfig: SystemConfigService,
    private titleService: Title,
    private logoService: LogoService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.getModuleParamConfig();
      this.setSysConfig();
    } else {
      this.router.navigate(['/']);
      console.log('error');
    }
    this.setTitleAndLogo();
    /*this.setTitle();*/
  }

  setSysConfig() {
    this.systemData = this.sysConfig.getData('sys_config');

    if (!this.systemData) {
      this.sysConfig.getSystemConifgData().subscribe((response) => {
        if (response.responseCode === ResponseCode.OK) {
          if (response && response.dataList && response.dataList.length !== 0) {
            this.sysConfig.setData('sys_config', response.dataList);
            // this.setTitle();
          }
        } else {
          this.toaster.error(response.responseMessage, 'ERROR');
        }
      });
    }
  }

  async getModuleParamConfig() {
    if (!this.moduleParamGlobConfigService.isDataSet()) {
      let responseData = await this.moduleParamConfigService.getAll(undefined, undefined).toPromise();
      if (responseData.responseCode == ResponseCode.OK) {
        this.moduleParamGlobConfigService.setData(responseData.dataList);
      }
    }
  }

  public setTitle() {
    if (this.sysConfig.sysConfig.get('TITLE_NAME')) {
      this.titleService.setTitle(this.sysConfig.sysConfig.get('TITLE_NAME'));
    }
  }

  setTitleAndLogo() {
    if (window.location.href.includes(PartnerConst.arthnik.partner)) {
      this.logoService.setLogo(PartnerConst.arthnik.logoURL);
      this.titleService.setTitle(PartnerConst.arthnik.title);
    } else if (window.location.href.includes(PartnerConst.ebisuerp.partner)) {
      this.logoService.setLogo(PartnerConst.ebisuerp.logoURL);
      this.titleService.setTitle(PartnerConst.ebisuerp.title);
    } else if (window.location.href.includes(PartnerConst.orbitshift.partner)) {
      this.logoService.setLogo(PartnerConst.orbitshift.logoURL);
      this.titleService.setTitle(PartnerConst.orbitshift.title);
    } else if (window.location.href.includes(PartnerConst.techieBrothers.partner)) {
      this.logoService.setLogo(PartnerConst.techieBrothers.logoURL);
      this.titleService.setTitle(PartnerConst.techieBrothers.title);
    }else if (window.location.href.includes(PartnerConst.infonoble.partner)) {
      this.logoService.setLogo(PartnerConst.infonoble.logoURL);
      this.titleService.setTitle(PartnerConst.infonoble.title);
    } else {
      this.logoService.setLogo(PartnerConst.neofruition.logoURL);
      this.titleService.setTitle(PartnerConst.neofruition.title);
    }
  }
}
