import { Pipe, PipeTransform } from '@angular/core';
import {LoginConfigData} from '../../../authentication/modal/login-config-data';
import {LocalDataStorageService} from '../../service/local-data-storage.service';

@Pipe({
  name: 'labelConfig'
})
export class LabelConfigPipe implements PipeTransform {


  constructor(private localDataStorageService : LocalDataStorageService) {
  }

  transform(key: string, defaultName?: any): string {
    let dataList : Array<LoginConfigData> = this.localDataStorageService.getLabelDataArray();

    if(typeof dataList == "string"){
      dataList = JSON.parse(dataList);
    }
    if(dataList){
      let d = dataList.find(f => f.keyName == key);
      if(d && d.labelName){
        return d.labelName;
      }
    }
    if(defaultName){
      return defaultName;
    }

    return key;
  }

}
