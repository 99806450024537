import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { SystemConfigSettingModel } from 'src/app/shared/model/system-config-setting-model';
import { SystemConfigService } from 'src/app/system-config/service/system-config.service';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'numberFormat',
})
@Injectable({
  providedIn: 'root',
})
export class NumberFormatPipe extends DecimalPipe implements PipeTransform {
  systemConfigService: SystemConfigService = new SystemConfigService();
  sysParam: SystemConfigSettingModel = new SystemConfigSettingModel(this.systemConfigService);
  // constructor(public _localeString: string) {
  //   super(_localeString);

  // }
  transform(value: any, args?: any): any {
    if (environment.CURRENCY === 1) {
      let minus;
      if (!isNaN(value)) {
        value = parseFloat('' + Number(value).toFixed(2));
        const currencySymbol = '₹';
        if (value < 0) {
          value = value * -1;
          minus = true;
        }
        const result = value.toString().split('.');

        let lastThree = result[0].substring(result[0].length - 3);
        const otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }
        let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

        if (result.length > 1) {
          output += '.' + result[1];
        }

        if (minus) {
          output = '-' + output;
        }

        return output;
      }
    } else {
      // console.log('this.sysParam', this.sysParam.decimalPointAllow);
      if (this.sysParam && this.sysParam.decimalPointAllow) {
        return super.transform(value, '1.0-' + this.sysParam.decimalPointAllow);
      }
      return super.transform(value, '1.0-2');
    }
  }
}
