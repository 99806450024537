import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {SystemConfigService} from "../../../../system-config/service/system-config.service";
import {SystemConfigModel} from "../../../../system-config/model/system-config-model";

@Component({
  selector: 'app-stock-header',
  templateUrl: './stock-header.component.html',
  styleUrls: ['./stock-header.component.css']
})
export class StockHeaderComponent implements OnInit {

    @Input("inwardUrl") inwardUrl : string = "";
    @Input("outwardUrl") outwardUrl : string = "";
    @Input("title") title : string = "";
    @Input("returnUrl") returnUrl: string = "";

    @Input("inwardLabel") inwardLabel : string = "Inward";
    @Input("outwardLabel") outwardLabel : string = "Outward";
    @Input("returnLabel") returnLabel : string = "Return";

    @Input("disableButtons") disable : boolean = false;

    @Input("disableInward") disableInward : boolean = false;
    @Input("disableOutward") disableOutward : boolean = false;
    @Input("disableReturn") disableReturn : boolean = false;

    public isOnlyInward: boolean;

    constructor(
      private router : Router,
      private sysConfigService: SystemConfigService
    ) {
    }

    ngOnInit() {
        this.isOnlyInward = this.sysConfigService.sysConfig ? this.sysConfigService.sysConfig.get('ONLY_INWARD') === 'YES' : false;
    }

    onOutwardClick(){
        this.router.navigate([this.outwardUrl]);
    }
    onReturnClick(){
        this.router.navigate([this.returnUrl]);
    }
    onInwardClick(){
        this.router.navigate([this.inwardUrl]);
    }

}
