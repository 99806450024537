import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'formatDate'
})

export class FormatDatePipe implements PipeTransform {

  transform(value: Date | moment.Moment | string | number, ...args: any[]): string {

    let dateFormate = '';
    let tempVal;
    if (!value) {
      return '';
    }
    tempVal = moment(value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    args.forEach(element => {
      if (tempVal === 'Invalid date') {
        dateFormate += moment(value).format(element);
      } else {
        dateFormate += moment(tempVal).format(element);
      }

    });

    return dateFormate;
    // return moment(value).format(args[0]);   // - default time
    // return moment.utc(value).format(args[0]); // - utc time
  }
}
