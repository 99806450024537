import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseData } from '../../core/modal/base-save-update-response';
import { AclDataModal } from '../sidebar/acl-data-modal';
import { environment } from '../../../environments/environment';
import { UserModal } from '../../modules/user/modal/user-modal';

@Injectable({
  providedIn: 'root'
})
export class ACLService {
  constructor(private http: HttpClient) {}

  getAclData(roleid): Observable<ResponseData<AclDataModal>> {
    return this.http.get<ResponseData<AclDataModal>>(environment.ACL_URL + '/getbyroleid/' + roleid);
  }

  getLoggedInUser(): Observable<ResponseData<UserModal>> {
    return this.http.get<ResponseData<UserModal>>(
      environment.SERVER_URL + environment.LOGGED_IN_USER_URL
    );
  }
}
