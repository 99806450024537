import { Component, OnInit, Input, Output, EventEmitter,OnChanges } from '@angular/core';
import { PaginationData } from '../../../modal/pagination-data';
import { LocalstorageService } from '../../../service/LocalstorageService/localstorage.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit,OnChanges {

  @Input("firstButtonName") firstBtnName : string = "First";
  @Input("lastButtonName") lastBtnName : string = "Last";
  @Input("nextButtonName") nextBtnName : string = "Next";
  @Input("preButtonName") preBtnName : string = "Pre";
  @Output("onChange")
  onChange : EventEmitter<PaginationData> = new EventEmitter<PaginationData>();

  @Input("totalRecords")
  public totalRecords : number;
  @Input("pageNumber")
  public currentPageNumber : number = 1;
  
  public pageSize : number;
  public totalPage : number;
  public jumpToPage : number;
  @Input("paginatioData")
  public pagingData : PaginationData;

  constructor(private localstorageService : LocalstorageService) { }
  ngOnInit() {
    this.pagingData = new PaginationData();
    this.initPage();
    this.getList();
    this.countTotalPage();
  }
  
  ngOnChanges(){
  this.countTotalPage();
  }
  getList(){
    if(this.pagingData){
      this.pagingData.pageNumber = this.currentPageNumber;
      this.pagingData.rowsPerPage = this.pageSize;
    }
    this.jumpToPage = this.currentPageNumber;
    this.onChange.emit(this.pagingData);
  }

  initPage(){
    if(!this.pageSize){
      this.pageSize = this.localstorageService.getPageSize();
    }
    //this.currentPageNumber = 1;
    this.pagingData.pageNumber = this.currentPageNumber;
    this.pagingData.rowsPerPage = this.pageSize;
  }
  countTotalPage(){
    this.totalPage = Math.ceil(this.totalRecords / this.pageSize);
    this.jumpToPage = this.currentPageNumber;
    if(this.currentPageNumber > this.totalPage){
      this.currentPageNumber = this.currentPageNumber - 1;
      this.getList();
    }
  }

  onJumpToPageClick(){
    if(this.jumpToPage < 1){
      this.jumpToPage = 1;
      this.currentPageNumber = 1;
      this.getList();
      return;
    }

    if(this.jumpToPage > this.totalPage ){
      this.jumpToPage = this.totalPage;
      this.currentPageNumber = this.totalPage;
      this.getList();
      return;
    }
    
    this.currentPageNumber = this.jumpToPage;
    this.getList();
  }

  onPageSizeChange(){
    this.currentPageNumber = 1;
    this.countTotalPage();
    this.getList();
  }
  onPageNoClick(pgNo : number){
    this.currentPageNumber = pgNo;
    this.getList();
  }
 
  onRefreshClick(){
    this.getList();
  }

  getPageArray():Array<number>{
    return Array(this.totalPage).fill(0).map((e,i)=>i+1);
  }

  onFirstClick(){
    if(this.currentPageNumber <= 1){
      return;
    }
    this.currentPageNumber = 1;
    this.getList();
  }

  onPreClick(){
    if(this.currentPageNumber == 1){
      this.currentPageNumber = this.totalPage;
      this.getList();
      return;
    }

    this.currentPageNumber = this.currentPageNumber - 1;
    this.getList();
  }

  onNextClick(){
    if(this.currentPageNumber == this.totalPage){
      this.currentPageNumber = 1;
      this.getList();
      return;
    }

    this.currentPageNumber = this.currentPageNumber + 1;
    this.getList();
  }

  onLastClick(){
    if(this.currentPageNumber == this.totalPage){
      return;
    }

    this.currentPageNumber = this.totalPage;
    this.getList();
  }
}
