import {Injectable, OnInit} from '@angular/core';
import {ModuleParamConfigModel} from "../../../modules/module-param-config/model/module-param-config-model";
import {ModuleParamConfigDetails} from "../../../modules/module-param-config/model/module-param-config-details";

@Injectable({
  providedIn: 'root'
})
export class ModuleParamGlobConfigService implements OnInit{

  private dataList : Array<ModuleParamConfigModel>;
  constructor() { }

  ngOnInit(): void {
    this.dataList = new Array<ModuleParamConfigModel>();
  }

  setData(list : Array<ModuleParamConfigModel>){
    this.dataList = list;
  }

  getLabel(moduleName : string,labelKey : string,defaultLabel : string) : string{
    let moduleParamDetails : ModuleParamConfigDetails = this.getModuleConfigDetails(moduleName,labelKey);
    if(!moduleParamDetails)
      return  defaultLabel;

    return moduleParamDetails.fieldLabel;
  }

  isRequired(moduleName : string,labelKey : string) : boolean{
    let moduleParamDetails : ModuleParamConfigDetails = this.getModuleConfigDetails(moduleName,labelKey);
    if(!moduleParamDetails)
      return  false;

    return moduleParamDetails.isRequired && moduleParamDetails.isVisible;
  }

  isVisible(moduleName : string,labelKey : string) : boolean{
    let moduleParamDetails : ModuleParamConfigDetails = this.getModuleConfigDetails(moduleName,labelKey);
    if(!moduleParamDetails)
      return  true;

    return moduleParamDetails.isVisible;
  }

  getModuleData(moduleName : string):ModuleParamConfigModel{
    if(!this.dataList || this.dataList.length < 1)
      return undefined;

    let data : ModuleParamConfigModel = this.dataList.find(
        value => value.moduleName == moduleName
    );

    if(!data)
      return undefined;

    return data;

  }

  isDataSet(){
    return this.dataList !=undefined;
  }

  private getModuleConfigDetails(moduleName : string,labelKey : string) : ModuleParamConfigDetails{
    if(!this.dataList || this.dataList.length < 1)
      return undefined;

    let data : ModuleParamConfigModel = this.dataList.find(
        value => value.moduleName == moduleName
    );

    if(!data || !data.fieldDetails)
      return undefined;

    let details : ModuleParamConfigDetails = data.fieldDetails
        .find(value => value.fieldKeyName == labelKey);

    if(!details)
      return undefined;

    return details;
  }
}
