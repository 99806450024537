import {UserModal} from "../../user/modal/user-modal";
import {PartnerModel} from "../../user/modal/partner-model";

export class UserProfileModel {
  id: number;
  user: UserModal;
  email: string;
  password: string;
  partner: PartnerModel;
}
