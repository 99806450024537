import { environment } from '../../../environments/environment';

export const PartnerConst = {
  neofruition: {
    title: 'INTOPT',
    logoURL: 'assets/images/logo-text.png',
    footerName: environment.FOOTER_TITLE ? environment.FOOTER_TITLE : 'Neofruition',
    footerURL: environment.FOOTER_URL ? environment.FOOTER_URL : 'https://www.neofruition.com/',
    sideLogoURL: 'assets/images/logo.png',
  },
  arthnik: {
    partner: 'arthnik',
    title: 'ERPNET',
    logoURL: 'http://erp.arthnik.com/images/erpnet_logo.png',
    footerName: 'Arthnik Solutions Pvt. Ltd.',
    footerURL: 'http://arthnik.com/',
    sideLogoURL: 'http://erp.arthnik.com/images/erpnet_logo.png',
  },
  ebisuerp: {
    partner: 'ebisu',
    title: 'INTOPT',
    logoURL: 'assets/images/logo-text.png',
    footerName: 'Ebisu Tech..',
    footerURL: 'http://www.ebisutech.com/',
    sideLogoURL: 'assets/images/logo.png',
  },
  orbitshift: {
    partner: 'orbitshift',
    title: 'INTOPT',
    logoURL: 'assets/images/logo-text.png',
    footerName: environment.FOOTER_TITLE ? environment.FOOTER_TITLE : 'Neofruition',
    footerURL: environment.FOOTER_URL ? environment.FOOTER_URL : 'https://www.neofruition.com/',
    sideLogoURL: 'assets/images/logo.png',
  },
  techieBrothers: {
    partner: 'techiebrothers',
    title: 'INTOPT',
    logoURL: 'assets/images/logo-text.png',
    footerName: 'TechieBrothers',
    footerURL: 'https://www.techiebrothers.in/',
    sideLogoURL: 'assets/images/logo.png',
  },
  infonoble: {
    partner: 'infonoble',
    title: 'INFONOBLE',
    logoURL: 'assets/images/infonoble-logo.png',
    footerName: 'Infonoble',
    footerURL: 'https://infonoble.com/',
    sideLogoURL: 'assets/images/infonoble-logo.png',
  },
  olatech: {
    partner: 'olatechs',
    title: 'OLATECH',
    logoURL: 'assets/images/olatechs.png',
    footerName: 'Olatech',
    footerURL: 'https://olatechs.com/',
    sideLogoURL: 'assets/images/olatechs.png',
  }
};
