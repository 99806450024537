import { Injectable } from '@angular/core';
import {LocalDataStorageService} from "../../../core/service/local-data-storage.service";
import {AclDataModal} from "../../../shared/sidebar/acl-data-modal";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AclConfigCheckService {

  constructor(private lclService : LocalDataStorageService) { }


  isViewAllow(serviceName : string){
    let isAllow : boolean = false;
    let aclList = this.lclService.getFromLocalstorage("aclData");
    let aclModule : Map<string,AclDataModal> = new Map<string,AclDataModal>();
      aclModule = new Map(JSON.parse(aclList));
      let acl : AclDataModal = aclModule.get(serviceName);

      if(acl){
        isAllow = acl.view == 1;
      }

      return isAllow;
    }
}
