import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDisableKeyPress]'
})
export class DisableKeyPressDirective {
  @Input() clickEvent: boolean;
  @Input() moduleIsDisable: boolean;
  constructor(
      private el: ElementRef
  ) { }

  ngAfterViewInit() {
    if (this.clickEvent) this.el.nativeElement.style.pointerEvents = 'none';
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    event.returnValue = false;
    event.preventDefault();
  }

}
