import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFocusDirective } from './auto-focus.directive';
import { DisableKeyPressDirective } from './disable-key-press.directive';
import { DropdownDirective } from './dropdown.directive';

@NgModule({
  declarations: [AutoFocusDirective, DisableKeyPressDirective, DropdownDirective],
  exports: [AutoFocusDirective, DisableKeyPressDirective, DropdownDirective],
  imports: [CommonModule],
})
export class AppDirectivesModule {}
