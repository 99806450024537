import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthUtilsService {

  constructor() { }

  getAuthToken():string{
    return "-1";
  }
}
