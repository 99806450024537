import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Injectable,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CommonSelectListService } from '../../shared/service/common-select-list.service';
import { ResponseCode } from '../../core/constant/response-code';
import { ToastrService } from 'ngx-toastr';
import { LoginConfigData } from '../../authentication/modal/login-config-data';
import { LocalDataStorageService } from '../../core/service/local-data-storage.service';
import { LoginService } from '../../authentication/login/service/login.service';
import { SystemConfigService } from '../../system-config/service/system-config.service';
import { SystemConfigModel } from '../../system-config/model/system-config-model';
import { AclConfigCheckService } from '../../modules/acl-config/service/acl-config-check.service';
import { ModuleParamGlobConfigService } from '../../core/service/moduleFieldConfig/module-param-glob-config.service';
import { ModuleParamConfigServiceService } from '../../modules/module-param-config/services/module-param-config-service.service';
import { ModuleParamConfigModel } from '../../modules/module-param-config/model/module-param-config-model';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { PartnerConst } from '../../shared/partner-constant/partner-const';
import { LogoService } from '../../core/service/logo-service/logo.service';
import { VersionService } from '../../shared/service/version.service';
import { UserModal } from 'src/app/modules/user/modal/user-modal';
import { ACLService } from 'src/app/shared/AclService/acl.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit, AfterViewChecked {
  public showFooter: boolean = true;
  public config: PerfectScrollbarConfigInterface = {};
  public isMiniMenu: boolean = false;
  public systemData: Array<SystemConfigModel>;
  isUserProfileAllow: boolean;
  public headerImgUrl = '';
  footerTitle = environment.FOOTER_TITLE ? environment.FOOTER_TITLE : 'Neofruition';
  footerUrl = environment.FOOTER_URL ? environment.FOOTER_URL : 'https://www.neofruition.com/';
  userProfile: UserModal;
  constructor(
    public router: Router,
    public common: CommonSelectListService,
    private aclConfigCheck: AclConfigCheckService,
    public toaster: ToastrService,
    private cdRef: ChangeDetectorRef,
    private titleService: Title,
    private logoService: LogoService,
    private aclService: ACLService,
    private localDataStorageService: LocalDataStorageService,
    private loginService: LoginService,
    private moduleParamGlobConfigService: ModuleParamGlobConfigService,
    private moduleParamConfigService: ModuleParamConfigServiceService,
    private sysConfig: SystemConfigService,
    private versionService: VersionService
  ) {}

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public orgId: number;
  versionJson: { version: string; date: Date };

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    this.initLoggedUser();
    let token: string = this.localDataStorageService.getAuthToken();

    if (!token) {
      this.router.navigate(['/']);
      return;
    }
    let labelConfigData: Array<LoginConfigData> = this.localDataStorageService.getLabelDataArray();

    if (!labelConfigData) {
      this.loginService.getLabelConfig().subscribe((responseData) => {
        if (responseData.dataList) {
          this.localDataStorageService.setLabelDataArray(responseData.dataList);
        }
      });
    }

    this.setModuleConfig();
    this.setSysConfig();
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.versionService.getVersion().subscribe((response) => {
      console.log('Version Data', response);
      this.versionJson = response;
    });
  }

  setSysConfig() {
    this.systemData = this.sysConfig.getData('sys_config');

    if (!this.systemData) {
      this.sysConfig.getSystemConifgData().subscribe((response) => {
        if (response.responseCode === ResponseCode.OK) {
          if (response && response.dataList && response.dataList.length !== 0) {
            this.sysConfig.setData('sys_config', response.dataList);
            this.setHeaderImage();
          }
        } else {
          this.toaster.error(response.responseMessage, 'ERROR');
        }
      });
    } else {
      this.setHeaderImage();
    }
  }

  initLoggedUser() {
    this.aclService.getLoggedInUser().subscribe((response) => {
      this.userProfile = new UserModal();
      this.userProfile = response.data;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  onLogoutClick() {
    localStorage.clear();
    this.localDataStorageService.clearLabelService();
    this.localDataStorageService.clear();
    this.router.navigate(['/']);
  }

  handleFooter(event) {
    this.showFooter = event;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getOrgData() {
    this.common.getOrganizationData().subscribe((response) => {
      if (response.responseCode === ResponseCode.OK) {
        if (response && response.data) {
          this.orgId = response.data.id;
          this.router.navigate(['/module/org-profile/addUpdate/' + this.orgId]);
        } else {
          // this.toaster.error('Organization data not found', 'Error');
          this.router.navigate(['/module/org-profile/']);
        }
      }
    });
  }

  async setModuleConfig() {
    let responseData = await this.moduleParamConfigService.getAll(undefined, undefined).toPromise();
    if (responseData.responseCode == ResponseCode.OK) {
      this.moduleParamGlobConfigService.setData(responseData.dataList);
    }
  }

  private setHeaderImage() {
    let token: string = this.localDataStorageService.getAuthToken();
    if (token) {
        this.setHeaderLogo();
    }
  }

  setHeaderLogo() {
    if (window.location.href.includes(PartnerConst.arthnik.partner)) {
      this.headerImgUrl = PartnerConst.arthnik.sideLogoURL;
      this.footerTitle = PartnerConst.arthnik.footerName;
      this.footerUrl = PartnerConst.arthnik.footerURL;
    } else if (window.location.href.includes(PartnerConst.ebisuerp.partner)) {
      this.headerImgUrl = PartnerConst.ebisuerp.sideLogoURL;
      this.footerTitle = PartnerConst.ebisuerp.footerName;
      this.footerUrl = PartnerConst.ebisuerp.footerURL;
    } else if (window.location.href.includes(PartnerConst.orbitshift.partner)) {
      this.headerImgUrl = PartnerConst.orbitshift.sideLogoURL;
      this.footerTitle = PartnerConst.orbitshift.footerName;
      this.footerUrl = PartnerConst.orbitshift.footerURL;
    } else if (window.location.href.includes(PartnerConst.techieBrothers.partner)) {
      this.headerImgUrl = PartnerConst.techieBrothers.sideLogoURL;
      this.footerTitle = PartnerConst.techieBrothers.footerName;
      this.footerUrl = PartnerConst.techieBrothers.footerURL;
    }else if (window.location.href.includes(PartnerConst.infonoble.partner)) {
      this.headerImgUrl = PartnerConst.infonoble.sideLogoURL;
      this.footerTitle = PartnerConst.infonoble.footerName;
      this.footerUrl = PartnerConst.infonoble.footerURL;
    } else if (window.location.href.includes(PartnerConst.olatech.partner)) {
      this.headerImgUrl = PartnerConst.olatech.sideLogoURL;
      this.footerTitle = PartnerConst.olatech.footerName;
      this.footerUrl = PartnerConst.olatech.footerURL;
    } else {
      this.headerImgUrl = PartnerConst.neofruition.sideLogoURL;
      this.footerTitle = PartnerConst.neofruition.footerName;
      this.footerUrl = PartnerConst.neofruition.footerURL;
    }
    this.checkForSystemConfig()
  }

  checkForSystemConfig(){
    //debugger
    if (this.sysConfig.sysConfig) {
      if(this.sysConfig.sysConfig.get('DYNAMIC_LOGO_CONFIGURATION') == 'YES'){
        if (this.sysConfig.sysConfig.get('HEADER_LOGO')) {
          this.headerImgUrl = this.sysConfig.sysConfig.get('HEADER_LOGO');
        } 
        if (this.sysConfig.sysConfig.get('FOOTER_TITLE')) {
          this.footerTitle = this.sysConfig.sysConfig.get('FOOTER_TITLE');
        }
        if (this.sysConfig.sysConfig.get('FOOTER_URL')) {
          this.footerUrl = this.sysConfig.sysConfig.get('FOOTER_URL');
        }
        if(!this.footerTitle && !this.footerUrl){
          this.showFooter = false;
        }
      }else{
        this.showFooter = false;
      }
    }
  }
}
