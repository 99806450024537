import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import {CoreModule} from '../../core/core.module';
import {ConfirmDialogService} from './service/confirm-dialog.service';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [
    CommonModule
  ],
  exports: [
      ConfirmationDialogComponent
  ]
})
export class ConfirmDialogBoxModule { }
