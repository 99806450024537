import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private _jsonURL = 'assets/version.json';
  constructor(
      private http:HttpClient
  ) { }

  public getVersion(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
}
