import { Injectable } from '@angular/core';
import {LoginConfigData} from '../../authentication/modal/login-config-data';

@Injectable({
  providedIn: 'root'
})
export class LocalDataStorageService {

  data : Map<string, any>;

  private labelDataList : Array<LoginConfigData>;
  constructor() { }

  setData(key: string,data : any){

    if(!this.data){
      this.data = new Map<string, any>();
    }

    this.data.set(key,data);
  }

  getData(key:string): any{
    if(this.data && this.data.has(key)){
      return this.data.get(key);
    }

    return undefined;
  }

  getDataAndRemove(key : string) : any{
    if(this.data && this.data.has(key)){
      let data : any =  this.data.get(key);
      this.data.delete(key);
      return data;
    }
    return undefined;
  }

  getFromLocalstorage(key : string) : any{

    if(this.data && this.data.has(key)){
      return this.data.get(key);
    }
    let lsData : string = localStorage.getItem(key);
    if(lsData != null){
      this.setData(key,lsData);

      return lsData;
    }

    return undefined;
  }

  getLabelDataArray() : Array<LoginConfigData>{
    if(!this.labelDataList){
      let data : string = localStorage.getItem("label_config");
      if(data && data.length > 0 && data != "")
        this.labelDataList = JSON.parse(JSON.stringify(data));
    }

    return this.labelDataList;

  }

  setLabelDataArray(data : Array<LoginConfigData>){
    this.labelDataList = data;
    localStorage.setItem("label_config",JSON.stringify(data));
  }

  getAuthToken() : string{
    return localStorage.getItem("token");
  }

  public clearLabelService(){
    this.labelDataList = undefined;
  }

  public clear(){
   if(this.data){
     this.data.clear();
   }

  }
}
