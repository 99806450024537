import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import {environment} from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {ResponseData} from '../../../core/modal/base-save-update-response';
import {LoginConfigData} from '../../modal/login-config-data';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private htttp: Http ,private httpClient : HttpClient) { }
  doLogin(username: string, password: string) {
     return this.htttp.post(environment.AUTH_URL, {
      username: username,
      password: password
 });
  }

  getLabelConfig():Observable<ResponseData<LoginConfigData>>{
     return  this.httpClient.get<ResponseData<LoginConfigData>>(environment.SERVER_URL + "labelConfig/getAllByPartner");
  }
}
