import {AfterViewInit, Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(
      private el: ElementRef
  ) { }

  ngAfterViewInit() {
    this.el.nativeElement.focus();
  }

}
