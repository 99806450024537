import { SystemConfigService } from '../../system-config/service/system-config.service';

export class SystemConfigSettingModel {
  isPkgTypeSupportOn: boolean;
  isSecondaryStockSupport: boolean;
  isRollNoStockSupport: boolean;
  isVendorCode: boolean;
  isLabTestInwardOn: boolean;
  partnerId: number;
  isRackSupport: boolean;

  defaultParam1Id: number;
  defaultParam2Id: number;
  defaultParam3Id: number;
  defaultWeavingProductId: number;
  decimalPointAllow = 2;
  isPartLedger: boolean;
  isSchedulingSupport: boolean;
  statusCompletedId = 7;
  defaultTapeBobinId: number;
  defaultCostParam: number;
  isWeavingProcessAutomated: boolean = false;
  labelSupport: boolean;
  customerSupport: boolean;
  label_key: any;
  isItemCodeSupport: boolean;
  isCBLFlow: boolean;
  isAllocation: boolean;
  primaryUomMtr: boolean;
  poCBLFlow: boolean;
  soCBLFlow: boolean;
  wastageMaterialTypeId: number;
  partnerKey: string;
  printPartner: string;
  defaultSystemDate: boolean;
  isDirectQuotation: boolean;
  public isAlpsConfiguration: boolean;
  constructor(public sysConfig: SystemConfigService) {
    this.sysConfiguration();
  }

  sysConfiguration() {
    this.wastageMaterialTypeId = parseInt(
      this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get('wastageMaterialTypeId') : null
    );
    this.defaultSystemDate = this.configurationCheck('DEFAULT_SYSTEM_DATE');
    this.isDirectQuotation = this.configurationCheck('DIRECT_QUOTATION');
    this.isPkgTypeSupportOn = this.configurationCheck('PACKING_TYPE_SUPPORT');
    this.isSecondaryStockSupport = this.configurationCheck('SECONDARY_STOCK_SUPPORT');
    this.isRollNoStockSupport = this.configurationCheck('ROLL_NO_SUPPORT');
    this.isVendorCode = this.configurationCheck('VENDOR_CODE_ON');
    this.isRackSupport = this.configurationCheck('RACK_SUPPORT');
    this.isLabTestInwardOn = this.configurationCheck('LAB_TEST_INWARD');
    this.isItemCodeSupport = this.configurationCheck('ITEM_CODE_SUPPORT');
    this.isAlpsConfiguration = this.configurationCheck('ALPS_CONFIGURATION');
    /**CBL Flow Key**/
    this.isCBLFlow = this.configurationCheck('CBL_FLOW_SUPPORT');

    /** Allocation & Reconcilation Support **/
    this.isAllocation = this.configurationCheck('ALLOCATION_SUPPORT');

    /**CBL Flow For PO Key**/
    this.poCBLFlow = this.configurationCheck('PO_CBL_FLOW_SUPPORT');

    /**CBL Flow For SO Key**/
    this.soCBLFlow = this.configurationCheck('SO_CBL_FLOW_SUPPORT');

    this.defaultWeavingProductId = parseInt(
      this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get('DEFAULT_MATERIAL') : '0'
    );

    this.defaultTapeBobinId = parseInt(
      this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get('DEFAULT_TAPEBOBINE_MATERIAL') : '0'
    );

    /**Label Support**/
    this.labelSupport = this.configurationCheck('LABEL_SUPPORT');

    this.customerSupport = this.configurationCheck('CUSTOMER_SUPPORT');

    this.label_key = this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get('LABEL_TYPE_ID') : 0;

    // Cost param (purity)
    this.defaultCostParam = parseInt(
      this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get('DEFAULT_COST_PARAM_1') : '0'
    );

    // PH
    this.defaultParam1Id =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('DEFAULT_STOCK_PARAM_1')
        ? this.sysConfig.sysConfig.get('DEFAULT_STOCK_PARAM_1')
        : 0;

    // moisture
    this.defaultParam2Id =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('DEFAULT_STOCK_PARAM_2')
        ? this.sysConfig.sysConfig.get('DEFAULT_STOCK_PARAM_2')
        : 0;

    // solubility
    this.defaultParam3Id =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('DEFAULT_STOCK_PARAM_3')
        ? this.sysConfig.sysConfig.get('DEFAULT_STOCK_PARAM_3')
        : 0;

    this.statusCompletedId =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('STATUS_COMPLETED_ID')
        ? this.sysConfig.sysConfig.get('STATUS_COMPLETED_ID')
        : 7;

    this.isPartLedger = this.configurationCheck('PARTY_ORDER_LEDGER_SUPPORT');
    this.isSchedulingSupport = this.configurationCheck('SCHEDULING_SUPPORT');

    this.isWeavingProcessAutomated =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('WEAVING_PROCESS_AUTOMATION')
        ? this.sysConfig.sysConfig.get('WEAVING_PROCESS_AUTOMATION').toLowerCase() === 'yes'
        : false;

    this.partnerId =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('PO_PRINT_PARTNER_ID')
        ? this.sysConfig.sysConfig.get('PO_PRINT_PARTNER_ID')
        : 0;

    this.primaryUomMtr =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('PRIMARY_UOM_MTR')
        ? this.sysConfig.sysConfig.get('PRIMARY_UOM_MTR').toLowerCase() === 'yes'
        : false;

    this.partnerKey =
      this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('PARTNER_NAME')
        ? this.sysConfig.sysConfig.get('PARTNER_NAME')
        : '';

    this.printPartner =
        this.sysConfig.sysConfig && this.sysConfig.sysConfig.get('PRINT_PARTNER_NAME')
          ? this.sysConfig.sysConfig.get('PRINT_PARTNER_NAME')
          : '';    
  }

  configurationCheck(param: string) {
    return this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get(param) === 'YES' : false;
  }
}
