import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResponseData} from "../../../core/modal/base-save-update-response";
import {UserProfileModel} from "../model/user-profile.model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class UserProfileService {
  constructor(public http: HttpClient) {
  }

  getProfile(): Observable<ResponseData<UserProfileModel>> {
    return this.http.get<ResponseData<UserProfileModel>>(environment.SERVER_URL + 'chemical/emailUserConfig/getEmailConfByLoggedInUser');
  }

  saveProfile(data: UserProfileModel): Observable<ResponseData<UserProfileModel>> {
    return this.http.post<ResponseData<UserProfileModel>>(environment.SERVER_URL + 'chemical/emailUserConfig/' + (data.id >= 0 ? 'update' : 'save'), data);
  }

}
