export const ResponseCode = {
    OK: 200,
    REDIRECT: 3302,
    INTERNAL_SERVER_ERROR: 5500,
    PARAMETER_NOT_FOUND: 4404,

    ITEM_CODE_NOT_FOUND: 4404011,
    ITEM_CODE_ALREADY_EXIST: 4404012,
    ITEM_CATEGORY_REQURIED: 4404013,
    ITEM_UOM_REQURIED: 4404014,
    ITEM_PURCHASE_GST_RATE_REQURIED: 4404015,
    ITEM_PURCHASE_GT_ZERO: 4404016,
    ITEM_SALE_GT_ZERO: 4404017,
    ITEM_SALE_GST_RATE_REQURIED: 4404018,
    ITEM_ROL_GT_ZERO: 4404019,

    ITEM_CATEGORY_NAME_NOT_FOUND: 4404021,
    ITEM_CATEGORY_NAME_ALREADY_EXIST: 4404022,
    UOM_NAME_NOT_FOUND: 4404031,
    UOM_NAME_ALREADY_EXIST: 4404032,

    GST_RATE_NAME_ALREADY_EXIST: 4404041,
    GST_RATE_GT_ZERO: 4404041,


    COUNTRY_NAME_NOT_FOUND: 4404051,
    COUNTRY_NAME_ALREADY_EXIST: 4404052,

    STATE_NAME_NOT_FOUND: 4404061,
    STATE_NAME_ALREADY_EXIST: 4404062,
    STATE_CODE_GT_ZERO: 4404053,
    STATE_CODE_ALREADY_EXIST: 4404064,

    CITY_NAME_NOT_FOUND: 4404071,
    CITY_NAME_ALREADY_EXIST: 4404072,

    ADDRESS_COUNTRY_CODE_NOT_FOUND: 4404081,
    ADDRESS_STATE_CODE_NOT_FOUND: 4404081,
    ADDRESS_CITY_CODE_NOT_FOUND: 4404081,
    ADDRESS_FOREIGN_KEY_NOT_FOUND: 4404081,
    ADDRESS_MOUDLE_NAME_NOT_FOUND: 4404081,

    CONTACT_CATEGORY_NAME_NOT_FOUND: 4404091,
    CONTACT_CATEGORY_NAME_ALREADY_EXIST: 4404092,
    CONTACT_OTHER_DETAILS_CONTACT_GT_ZERO: 4404101,
    CONTACT_BANK_DETAILS_CONTACT_GT_ZERO: 4404101,

}
