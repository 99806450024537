import { Injectable } from '@angular/core';
import { SystemConfigModel } from '../model/system-config-model';
import { LoginConfigData } from '../../authentication/modal/login-config-data';
import { Observable } from 'rxjs';
import { ResponseData } from '../../core/modal/base-save-update-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { InjectorInstance } from 'src/app/app.module';

@Injectable({
  providedIn: 'root',
})
export class SystemConfigService {
  public sysData: Map<string, Array<SystemConfigModel>>;

  public sysDataList: Array<SystemConfigModel>;
  public sysConfig: Map<string, any>;
  public http: HttpClient;
  constructor() {
    this.http = InjectorInstance.get(HttpClient);
  }

  setSystemConfigDataArray(data: Array<SystemConfigModel>) {
    this.sysDataList = data;
    localStorage.setItem('sys_config', JSON.stringify(data));
  }

  getSystemConfigDataArray(): Array<SystemConfigModel> {
    if (!this.sysDataList) {
      const sysConfigData: string = localStorage.getItem('sys_config');
      if (sysConfigData && sysConfigData.length > 0 && sysConfigData !== '')
        this.sysDataList = JSON.parse(JSON.stringify(sysConfigData));
    }

    return this.sysDataList;
  }

  setData(key: string, sysData: any) {
    if (!this.sysData) {
      this.sysData = new Map<string, any>();
    }
    this.sysData.set(key, sysData);
    this.setConfiguration(key);
  }

  getData(key: string): any {
    if (this.sysData && this.sysData.has(key)) {
      return this.sysData.get(key);
    }
    return undefined;
  }

  async setConfiguration(key) {
    if (this.sysData.has(key)) {
      this.sysConfig = new Map<string, string>();
      const data: SystemConfigModel[] = this.sysData.get(key);
      await data.forEach((ls) => {
        if (!this.sysConfig.has(ls.keyName)) {
          this.sysConfig.set(ls.keyName, ls.value);
        }
      });
    }
    return undefined;
  }

  getFromLocalstorage(key: string): any {
    if (this.sysData && this.sysData.has(key)) {
      return this.sysData.get(key);
    }
    const sysLsData: string = localStorage.getItem(key);
    if (sysLsData !== null) {
      this.setData(key, sysLsData);

      return sysLsData;
    }

    return undefined;
  }

  getSystemConifgData(): Observable<ResponseData<SystemConfigModel>> {
    return this.http.get<ResponseData<SystemConfigModel>>(environment.SERVER_URL + 'sysConfig/getAllByPartner');
  }

  getById(id: number): Observable<ResponseData<SystemConfigModel>> {
    return this.http.get<ResponseData<SystemConfigModel>>(environment.SERVER_URL + 'sysConfig/' + id);
  }

  sysSaveUpdate(data: SystemConfigModel, isUpdate: boolean): Observable<ResponseData<SystemConfigModel>> {
    return this.http.post<ResponseData<SystemConfigModel>>(
      environment.SERVER_URL + 'sysConfig/' + (isUpdate ? 'update' : 'save'),
      data
    );
  }

  deleteSysData(data: SystemConfigModel): Observable<ResponseData<SystemConfigModel>> {
    return this.http.post<ResponseData<SystemConfigModel>>(environment.SERVER_URL + 'sysConfig/delete', data);
  }
}
