import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private readonly PAGE_SIZE : string = "pageSize";
  private readonly DEFAULT_PAGE_SIZE : number = 10;
  constructor() { }


  getPageSize() : number{
    let pageSizeStr = localStorage.getItem(this.PAGE_SIZE);
    let pageSize : number;
    if(pageSizeStr){
      pageSize = parseInt(pageSizeStr.toString().trim());
    }else{
      pageSize = this.DEFAULT_PAGE_SIZE;
    }
    return pageSize;
  }
}
