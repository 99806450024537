import { RoleModal } from '../../role/modal/role-modal';
import { PartnerModel } from './partner-model';
import { DepartmentModal } from '../../department/modal/department-modal';
import { StockAreaModal } from '../../stock-area/modal/stock-area-modal';

export class UserModal {
  public staffid: number;
  public firstname: string;
  public lastname: string;
  public username: string;
  public phone: string;
  public address: string;
  public password: string;
  public email: string;
  public role: RoleModal;
  identitytype: string;
  identityid: string;
  department: DepartmentModal;
  organization: PartnerModel;
  manager: UserModal;
  stockAreas: Array<StockAreaModal>;

  //For Angular USE
  roleString: string;
  nameString: string;
  file: File[];
  footerSign: any;
}
