import { ModuleParamGlobConfigService } from '../../../core/service/moduleFieldConfig/module-param-glob-config.service';

export class userProfileParamConfigModel {
  constructor(private moduleParamConfigGlob: ModuleParamGlobConfigService) {}

  paramData = {
    user: {
      label: this.moduleParamConfigGlob.getLabel('UserProfile', 'user', 'User'),
      isRequired: this.moduleParamConfigGlob.isRequired('UserProfileModel', 'user'),
      isVisible: this.moduleParamConfigGlob.isVisible('UserProfileModel', 'user'),
    },
    email: {
      label: this.moduleParamConfigGlob.getLabel('UserProfile', 'email', 'Email'),
      isRequired: this.moduleParamConfigGlob.isRequired('UserProfileModel', 'email'),
      isVisible: this.moduleParamConfigGlob.isVisible('UserProfileModel', 'email'),
    },
    password: {
      label: this.moduleParamConfigGlob.getLabel('UserProfile', 'password', 'Password'),
      isRequired: this.moduleParamConfigGlob.isRequired('UserProfileModel', 'password'),
      isVisible: this.moduleParamConfigGlob.isVisible('UserProfileModel', 'password'),
    },
    partner: {
      label: this.moduleParamConfigGlob.getLabel('UserProfile', 'partner', 'Partner'),
      isRequired: this.moduleParamConfigGlob.isRequired('UserProfileModel', 'partner'),
      isVisible: this.moduleParamConfigGlob.isVisible('UserProfileModel', 'partner'),
    },
  };
}
